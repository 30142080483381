/*
.fabrx-dashboard .menu-category>li>a:hover, .fabrx-dashboard .menu-category>li>a[aria-expanded="true"] {
    background-color: transparent;
    color: #06c3ff!important;
    border-color: #06c3ff!important;
}
.fabrx-dashboard .menu-category>li.active>a {
    color: #06c3ff!important;
    border-color: #06c3ff!important;

}

 */
.menu-category li a:hover, .menu-category li.active>a {
    background-color: #E6F0FF!important;
}
